import React, { useState, useEffect } from "react";
import Header from "../Common/Header";
import Footer from "../Common/Footer";
import { Link } from 'react-router-dom';
function Home() {
 
  return (
    <>
      <div><Header /> </div>
      <div>
      <div className="banner">
      <img
        src="assets/images/banner.jpg"
        alt="Taneja Capital"
        title="Top CA firm in delhi-ncr"
      />
      <div className="caption">
        <span>A Solution For Your </span>
        <h1 title="Top CA firm in delhi-ncr">Long Term Wealth Creation</h1>
      </div>
    </div>
    {/* end .rev_slider */}
    {/*======= HOME SLIDER END =========*/}
    {/* =-=-=-=-=-=-= Call To Action =-=-=-=-=-=-= */}
    <div className="parallex-small ">
      <div className="container">
        <div className="row custom-padding-20 ">
          <div className="col-md-8 col-sm-8">
            <div className="parallex-text">
              <h4 title="Top CA firm in delhi-ncr">
                Is Your Financial Ventures Making
                <br /> Any Diffrence In Your Financial Goal?
              </h4>
            </div>
            {/* end subsection-text */}
          </div>
          {/* end col-md-8 */}
          <div className="col-md-4 col-sm-4">
            <div className="parallex-button">
              {" "}
              <Link to="/contact-us" target="_blank"
                className="btn btn-lg btn-clean">Contact US</Link>
              {/* <a
                href="contact-us.html"
                target="_blank"
                className="btn btn-lg btn-clean"
              >
                Contact US
              </a> */}
            </div>
            {/* end parallex-button */}
          </div>
          {/* end col-md-4 */}
        </div>
        {/* end row */}
      </div>
      {/* end container */}
    </div>
    <section className="custom-padding services">
      <div className="container">
        {/* title-section */}
        <div className="main-heading">
          <h2>Welcome to Taneja Capital </h2>
          <p
            style={{ textAlign: "left", margin: "0 0 10px 0", width: "100%" }}
            title="Top CA firm in delhi-ncr"
          >
            Taneja Capital Ventures Pvt Ltd. is a customer-focused financial
            services firm which offers personalized services. We aim to be a{" "}
            <b style={{ textTransform: "uppercase" }}>"trusted Partner"</b> for
            its clients on the platform of transparency and performance.{" "}
          </p>
          <div className="main-heading">
            <p
              style={{ textAlign: "left", margin: 0, width: "100%" }}
              title="Top CA firm in delhi-ncr"
            >
              We believe in a thoughtful, straightforward investment philosophy -
              long-term investing with an emphasis on quality and diversification.
              All three of these elements work together. Our philosophy is one of
              the ways we can help you make sense of investing.{" "}
            </p>
            <h4
              style={{
                fontSize: 21,
                margin: "20px 0px 5px",
                color: "#3498db",
                textTransform: "capitalize",
                fontFamily: '"Source Sans Pro", sans-serif'
              }}
              title="Top CA firm in delhi-ncr"
            >
              A long-Term strategy
            </h4>
            <p
              style={{ textAlign: "left", margin: 0, width: "100%" }}
              title="Top CA firm in delhi-ncr"
            >
              The market changes every day, but your financial goals probably
              don't. And your investment strategy shouldn't either.
            </p>
            <p
              style={{ textAlign: "left", margin: 0, width: "100%" }}
              title="Top CA firm in delhi-ncr"
            >
              We believe a long-term strategy is the best way to build and
              preserve your financial security. But remember that "buy and hold"
              doesn't mean "buy and ignore." You should review your portfolio at
              least once a year to make sure you're still on track to meet your
              financial goals.
            </p>
            <h4
              style={{
                fontSize: 21,
                margin: "20px 0px 5px",
                color: "#3498db",
                textTransform: "capitalize",
                fontFamily: '"Source Sans Pro", sans-serif'
              }}
              title="Top CA firm in delhi-ncr"
            >
              Quality
            </h4>
            <p
              style={{ textAlign: "left", margin: 0, width: "100%" }}
              title="Top CA firm in delhi-ncr"
            >
              After years of experience, we know that quality matters. Our
              Research department recommends stocks, bonds and mutual funds that
              we believe offer the potential for solid performance over time –
              through good and bad markets. That's why we don't recommend penny
              stocks, individual junk bonds, options or commodities, and we don't
              hop on the bandwagon of investment fads. We believe these are too
              risky and won't deliver the performance you need to reach your
              goals.
            </p>
            <h4
              style={{
                fontSize: 21,
                margin: "20px 0px 5px",
                color: "#3498db",
                textTransform: "capitalize",
                fontFamily: '"Source Sans Pro", sans-serif'
              }}
            >
              Diversification
            </h4>
            <p
              style={{ textAlign: "left", margin: 0, width: "100%" }}
              title="Top CA firm in delhi-ncr"
            >
              No investment is risk free. But finding the right balance between
              that risk and your return potential is at the heart of any solid
              investment strategy.
            </p>
            <p style={{ textAlign: "left", margin: 0, width: "100%" }}>
              While diversification cannot protect you against a loss, it is one
              way you can help reduce your risk. If your money is invested in just
              one or a few investments and one of them experiences some
              challenges, your entire financial strategy could be in trouble.
              Instead, we recommend building a portfolio that includes different
              types of investments that may perform differently over time. We work
              together to help you stay on track to meet your long-term goals and
              limit your risk.
            </p>
          </div>
        </div>
        {/* End title-section */}
        {/* Row */}
        <div className="main-boxes">
          <div className="row">
            <div className="col-sm-6 col-md-4 col-xs-12">
              <div className="services-grid-1 clearfix">
                <div className="service-image">
                  <a href="/">
                    <img
                      alt="Taneja Capital"
                      title="Top CA firm in delhi-ncr"
                      src="assets/images/1.jpg"
                    />
                  </a>
                </div>
                <div className="services-text">
                  <h4> Risk Profiling </h4>
                </div>
              </div>
              {/* end services-grid-1 */}
            </div>
            {/* end col-sm-4 */}
            <div className="col-sm-6 col-md-4 col-xs-12 clearfix">
              <div className="services-grid-1">
                <div className="service-image">
                  <a href="/">
                    <img
                      alt="Taneja Capital"
                      title="Top CA firm in delhi-ncr"
                      src="assets/images/3.jpg"
                    />
                  </a>
                </div>
                <div className="services-text">
                  <h4>Fund Analysis</h4>
                </div>
              </div>
              {/* end services-grid-1 */}
            </div>
            {/* end col-sm-4 */}
            <div className="col-sm-6 col-md-4 col-xs-12 clearfix">
              <div className="services-grid-1">
                <div className="service-image">
                  <a href="/">
                    <img
                      alt="Taneja Capital"
                      title="Top CA firm in delhi-ncr"
                      src="assets/images/2.jpg"
                    />
                  </a>
                </div>
                <div className="services-text">
                  <h4>Fund Allocation</h4>
                </div>
              </div>
              {/* end services-grid-1 */}
            </div>
            {/* end col-sm-4 */}
            <div className="col-sm-6 col-md-4 col-xs-12 clearfix">
              <div className="services-grid-1">
                <div className="service-image">
                  <a href="/">
                    <img
                      alt="Taneja Capital"
                      title="Top CA firm in delhi-ncr"
                      src="assets/images/4.jpg"
                    />
                  </a>
                </div>
                <div className="services-text">
                  <h4>Portfolio Construction</h4>
                </div>
              </div>
              {/* end services-grid-1 */}
            </div>
            {/* end col-sm-4 */}
            <div className="col-sm-6 col-md-4 col-xs-12 clearfix">
              <div className="services-grid-1">
                <div className="service-image">
                  <a href="/">
                    <img
                      alt="Taneja Capital"
                      title="Top CA firm in delhi-ncr"
                      src="assets/images/5.jpg"
                    />
                  </a>
                </div>
                <div className="services-text">
                  <h4>Portfolio Review</h4>
                </div>
              </div>
              {/* end services-grid-1 */}
            </div>
            {/* end col-sm-4 */}
            <div className="col-sm-6 col-md-4 col-xs-12 clearfix">
              <div className="services-grid-1">
                <div className="service-image">
                  <a href="/">
                    <img
                      alt="Taneja Capital"
                      title="Top CA firm in delhi-ncr"
                      src="assets/images/6.jpg"
                    />
                  </a>
                </div>
                <div className="services-text">
                  <h4>Rebalancing Portfolio</h4>
                </div>
              </div>
              {/* end services-grid-1 */}
            </div>
            {/* end col-sm-4 */}
          </div>
        </div>
        {/* Row End */}
      </div>
      {/* end container */}
    </section>
    {/* =-=-=-=-=-=-= Our Services-end =-=-=-=-=-=-= */}
    <section className="testimonial-bg parallex section-padding text-center">
      <div className="container">
        <div className="row clearfix">
          {/*Column*/}
          <div className="col-md-12 col-sm-12 col-xs-12 ">
            <div className="choose-services">
              <ul className="choose-list">
                {/* feature */}
                <li>
                  <div className="choose-box">
                    <div className="choose-box-content">
                      <h4
                        style={{
                          textAlign: "center",
                          fontSize: "34px",
                          lineHeight: "34px"
                        }}
                      >
                        Sound Advice, Solid Execution, and Timely Review – A
                        Winning Combination For Happy Investing!
                      </h4>
                      <p
                         style={{
                          textAlign: "center",
                        }}
                        title="Top CA firm in delhi-ncr"
                      >
                        Our state of art contemporary online, multi-class platform
                        enables you to make online investments across Asset
                        classes including PMS, Mutual funds, Equities and Bonds
                        etc. in consort with the financial advice and guidance
                        provided by Taneja Capital.
                      </p>
                    </div>
                  </div>
                </li>
              </ul>
              {/* end choose-list */}
            </div>
          </div>
          {/* Quote Form */}
        </div>
      </div>
      {/* end container */}
    </section>
    {/* =-=-=-=-=-=-= Quote Seection =-=-=-=-=-=-= */}
    {/* =-=-=-=-=-=-= PARALLEX TESTIMONILS END =-=-=-=-=-=-= */}
    {/* =-=-=-=-=-=-= Blog & News =-=-=-=-=-=-= */}
    <section id="blog" className="custom-padding">
      <div className="container">
        {/* title-section */}
        <div className="main-heading main-heading2 text-center">
          <h2 title="Top CA firm in delhi-ncr">
            Put The Power Of Personal Attention To Work For You.
          </h2>
          <div className="">
            {" "}
            <Link to="/contact-us" target="_blank"
              className="btn btn-lg btn-clean">Contact US</Link>
            {/* <a
              href="contact-us.html"
              target="_blank"
              className="btn btn-lg btn-clean"
            >
              Contact US
            </a> */}
          </div>
        </div>
        {/* End title-section */}
        {/* Row */}
        <div className="row">
          <div className="col-sm-12 col-xs-12 col-md-12">
            <div className="clearfix" />
          </div>
        </div>
        {/* Row End */}
      </div>
      {/* end container */}
    </section>
      </div>
      <div><Footer /> </div>
    </>
  );
}

export default Home;
